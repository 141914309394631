import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { z } from "zod";

const createShiftAccountLogRequestSchema = z.object({
  text: z.string(),
  accountId: z.string(),
  shiftId: z.string(),
  meta: z.object({
    shiftId: z.string(),
  }),
});

export type CreateShiftAccountLogRequest = z.infer<typeof createShiftAccountLogRequestSchema>;

export function useCreateShiftAccountLog(
  options: UseMutationOptions<void, AxiosError, CreateShiftAccountLogRequest> = {}
) {
  return useMutation({
    mutationFn: async (data) => {
      await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/account-log/create`,
        data: {
          text: data.text,
          accountId: data.accountId,
          shiftId: data.shiftId,
          meta: {
            shiftId: data.shiftId,
          },
        },
        requestSchema: createShiftAccountLogRequestSchema,
        responseSchema: z.any(),
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.ACCOUNT_LOG_CREATE_FAILED,
    },
    ...options,
  });
}
